// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useLayoutEffect, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reindexCards, buttonColors, toggleRandomState, 
        updateScrollPosition, setCurrentSet, shuffleAndModifyKeys, 
        setCurrentSubMenu, initializeSpeech, setSpeakerState } from './actions';
import { persistor } from './store';

import "./AppStyles.css";
import "./MenuStyles.css";

import iconShuffled from "./assets/img/shuffled.png"
import iconUserList from "./assets/img/star.png"
// import iconEmptyUserList from "./assets/img/emptystar.png"
import iconHome from "./assets/img/home.png"
import iconSpeaker from "./assets/img/speaker.png"
import iconNoSpeaker from "./assets/img/nospeaker.png"

import Cards from "./Cards";

export default function MainMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((state: any) => state.app.userList);

  const randomState = useSelector((state: any) => state.app.randomState);
  const scrollPosition = useSelector((state: any) => state.app.scrollPosition);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  const flashcarddata = useSelector((state: Record<string, any>) => state.flashcard.flashcarddata);

  // useLayoutEffect activates before the next component renders
  useLayoutEffect(() => {
  	window.scrollTo(0, scrollPosition);
    return () => {
      dispatch(updateScrollPosition(window.scrollY));
    };
  }, [scrollPosition, dispatch]);

  // Set the current set to the user-defined list
  const selectUserList = () => {
    // initialize speechSynthesis with user interaction
    initializeSpeech();

    let carddata = randomState ? shuffleAndModifyKeys(userList) : reindexCards(userList);
    dispatch(setCurrentSet(carddata));
    dispatch(setCurrentSubMenu("MainMenu"));    
    navigate("/cards");
  };

  const handleShuffleChange = () => {
    dispatch(toggleRandomState());
  };

  function returnHome() {
    navigate("/");
  }

	const handlePurgeLocalStorage = () => {
	  persistor.purge();
	  // Optionally, you can reload the page to reflect the changes
	  window.location.reload();
	};

  // const subMenuOptions = [
  //   { label: '一年上', setName: '一年級上學期' },
  //   { label: '一年下', setName: '一年級下學期' },
  //   { label: '部首', setName: '部首' },
  //   { label: '生活', setName: '注音符號' },
  //   // Add more options as needed
  // ];

  const subMenuOptions = Object.keys(flashcarddata);
  // calculate button height size based on number of options
  const subMenuButtonHeight = Math.max( 100, 300 / Math.ceil( subMenuOptions.length / 2 ) );

  // calculate button font size based on max length of text of options
  const subMenuKeyLengths = Object.keys(flashcarddata).map(key => key.length);
  const maxLength = Math.max(...subMenuKeyLengths);
  const subMenuFontSize = (maxLength < 4) ? "30px" : (maxLength == 4) ? "24px" : (maxLength == 5) ? "21px" : "18px";

  // // calculate different font sizes for each button
  // const subMenuFontSize = (label: any) => {
  //   const len = label.length;
  //   return (len < 4) ? "30px" : (len == 4) ? "24px" : (len == 5) ? "20px" : "18px";
  // }

  const handleSubMenuClick = (setName: any) => {
    // initialize speechSynthesis with user interaction
    initializeSpeech();
    dispatch(setCurrentSubMenu(setName));
    navigate('/submenu')
  };

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());
  }


  return (
  	<div className="mainmenu">
	  	<div className="menu">練習國字</div>

	  	<div className="topbody">
	     	<button onClick={returnHome} className="homeButton" disabled>
		   		<img src={iconHome} alt="空" />
	     	</button>
		   	<button onClick={handleShuffleChange} className={`toggle-button ${randomState ? '隨機順序' : '正常秩序'}`}>
          <img src={iconShuffled} alt="隨機順序" />
      	</button>
        <button onClick={handleSpeakerClick} className="speakerButton">
          <img src={speaker ? iconSpeaker : iconNoSpeaker} alt="說" />
        </button>
		   	<button onClick={selectUserList} className="userListButton">
		   		<img src={iconUserList} alt="星" />
		   	</button>
      </div>

      <div className="basemenu">
        {subMenuOptions.map((option, index) => (
          <button key={index} 
          				onClick={() => handleSubMenuClick(option)}
                  style={{ backgroundColor: buttonColors[index % buttonColors.length],
                           height: subMenuButtonHeight,
                           fontSize: subMenuFontSize  }}>
{/*                           fontSize: subMenuFontSize(option)  }}> */}
            {option}
          </button>
        ))}
      </div>

	   	<div className="footer">
		  	<small>
		  		秀朗國小補校
		  		<br/>
		  		App創作者：劉喆 2024
		  	</small>
			</div>
		</div>
  )
}
